import "intersection-observer";
import "styles/global.scss";
import "regenerator-runtime/runtime";

import texts from "/src/assets/texts/en.json";

import React from "react";

import TextsContext from "/src/cntx/TextsContext";

const App = ({ Component, pageProps }) => {
  return (
    <TextsContext.Provider value={texts}>
      <Component {...pageProps} />
    </TextsContext.Provider>
  );
};

export default App;
